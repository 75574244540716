* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
  }
  
  .home,
  .services,
  .products,
  .sign-up {
    display: flex;
    height: 90vh;
    /* align-items: center; */
    justify-content: center;
    font-size: 3rem;
  }
  
  .services {
    /* background-image: url('../src/contactlogo.png'); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color:  rgb(0, 0, 0);
    font-size: 30px;
  }
  
  .products {
    /* background-image: url('SRCimages/racklogo.png'); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: rgb(0, 0, 0);
    font-size: 100px;
  }
  
  .sign-up {
    /* background-image: url('/images/img-8.jpg'); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }

  .about__info{
    display: flex;
    text-align: justify;
    margin-top: 20px;
    margin-right: 500px;
    margin-left: 50px;

  }

 