*, ::after, ::before {
    box-sizing: border-box;
}

.roadmap {
    list-style: none;
    padding: 1rem 0;
    position: relative;
  }
  

.roadmap > li > .roadmap-dot1.done1 .done2 .done3 {
    background-color: #3e9392;
}

.roadmap > li > .roadmap-dot1 {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 10%;
    left: 50%;
    margin-left: -10px;
    background-color: #3e9392;
    border-radius: 50%;
}

.roadmap-dot2 {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 35%;
    left: 50%;
    margin-left: -10px;
    background-color: #3e9392;
    border-radius: 50%;
}

.roadmap-dot3 {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 60%;
    left: 50%;
    margin-left: -10px;
    background-color: #3e9392;
    border-radius: 50%;
}

.roadmap-dot4 {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 80%;
    left: 50%;
    margin-left: -10px;
    background-color: #3d4663;
    border-radius: 50%;
}

.roadmap > li > .roadmap-dot.half {
    background: linear-gradient(90deg, #3e9392 50%, #3d4663 50%);
}

.roadmap > li > .roadmap-panel {
    width: 47%;
    float: left;
}

.roadmap > li > .roadmap-panel {
    padding: 1.5rem;
    position: relative;
    background: #333;
    border-radius: 25px;
}



.roadmap > li.roadmap-inverted > .roadmap-panel {
    float: right;
}

.roadmap:before {
    top: 15px;
    bottom: 15px;
    position: absolute;
    content: " ";
    width: 3px;
    background-color: #333;
    left: 50%;
    margin-left: -1.5px;
}

.roadmap > li:after {
    clear: both;
}

.roadmap > li:before, .roadmap > li:after {
    content: " ";
    display: table;
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 10px;
    margin-left: 10px;
    justify-content: center;
}


.map-text{
    color:#fff;
    font-size:1.5rem;
}

.map-title{
    color:#0ac9aa
}
.line-break{
    border-top: 3px solid #333;
}

