.footer-container{
    background-color: #242424;
    padding: 4 rem 0 2rem 0;
    display: flex;
    flex-direction: colum;
    justify-content: center;
    align-items: center;
    padding-top: 4%;
}

/* .copy_right{
    color: white;
} */

.copy_right{
  
    width: 100%;
    color: #fff;
    line-height: 40px;
    font-size: 1.7em;
    text-align: center;
    bottom:0;
}