.player-wraper {
    position: relative;
    padding-top:56.25%;/*player ration: 100/ (1280 / 720) */
    margin-left: 3px;
    margin-right: 3px;
}

.react-player{
    position:absolute;
    top:0;
    left:0;
    padding-top: 2px;
}